package fi.bullpen.kmpapp.composables

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.svg.Path
import org.jetbrains.compose.web.svg.Svg
import org.jetbrains.compose.web.svg.fill
import org.jetbrains.compose.web.svg.xmlns
import org.w3c.dom.svg.SVGElement


@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun PlusIcon(attrs: AttrBuilderContext<SVGElement>? = null) {
    Svg(viewBox = "0 0 24 24", attrs = {
        xmlns("http://www.w3.org/2000/svg")
        fill("none")
        attr("stroke-width", "1.5")
        attr("stroke", "currentColor")
        attrs?.invoke(this)
    }) {
        Path(d = "M12 4.5v15m7.5-7.5h-15", attrs = {
            attr("stroke-linecap", "round")
            attr("stroke-linejoin", "round")
        })
    }
}
