package fi.bullpen.kmpapp.screens.turnkeyManagement.composeables

import androidx.compose.runtime.Composable
import fi.bullpen.kmpapp.composables.XmarkIcon
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement


@Composable
private fun CloseButton(attrs: AttrBuilderContext<HTMLDivElement>? = null, onClick: () -> Unit) {
    Div({
        classes(
            "absolute",
            "top-2",
            "right-2",
            "bg-transparent",
            "rounded-lg",
            "text-sm",
            "h-8",
            "w-8",
            "ms-auto",
            "inline-flex",
            "justify-center",
            "items-center",
        )
        style { width(25.px); height(25.px) }
        attrs?.invoke(this)
        onClick {
            // Prevent click from propagating and opening the API key
            it.stopPropagation()
            onClick()
        }
    }) {
        XmarkIcon({ classes("w-3", "h-3") })
    }
}

@Composable
fun CloseButtonStd(onClick: () -> Unit) {
    CloseButton({
        classes(
            "text-gray-400",
            "hover:bg-gray-200",
            "hover:text-gray-900",
            "dark:hover:bg-gray-600",
            "dark:hover:text-white"
        )
    }, onClick)
}

@Composable
fun CloseButtonError(onClick: () -> Unit) {
    CloseButton({
        classes(
            "text-red-700", "hover:bg-red-200", "hover:text-red-900", "dark:hover:bg-red-600", "dark:hover:text-white"
        )
    }, onClick)
}
