package fi.bullpen.kmpapp.screens.turnkeyInitEmailRecovery

import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper

data class TurnkeyInitEmailRecoveryScreenState(
    val telegramBotName: String,
    val recoveryState: RecoveryState = RecoveryState.InputEmail(null),
    val iframeStamper: IframeStamper? = null,
    val defaultPasskeyName: String? = null
)

sealed interface RecoveryState {
    sealed interface AwaitingAction : RecoveryState
    data class InputEmail(val errorMessage: String?) : AwaitingAction
    data class InputRecoveryCode(val errorMessage: String?) : AwaitingAction
    data object InProgress : RecoveryState
    data object Success : RecoveryState
    data class Error(val message: String) : AwaitingAction
}
