package fi.bullpen.kmpapp.screens.turnkeyManagement.composeables

import androidx.compose.runtime.Composable
import fi.bullpen.kmpapp.composables.ProgressSpinner
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text


@Composable
fun PageSection(title: String, isLoading: Boolean = false, content: @Composable () -> Unit) {
    Div({
        classes("m-4")
    }) {
        Div({
            classes(
                "w-full",
                "px-4",
                "py-2",
                "border",
                "border-gray-200",
                "bg-gray-50",
                "rounded-t-xl",
                "dark:border-gray-600",
                "dark:bg-gray-700"
            )
        }) {
            Div({ classes("flex", "flex-row", "items-center", "justify-between") }) {
                H2({
                    classes("text-2xl", "favorit", "leading-9", "tracking-tight", "text-zinc-900")
                }) {
                    Text(title)
                }
                if (isLoading) {
                    ProgressSpinner { classes("w-8", "h-8") }
                }
            }
        }
        Div({
            classes(
                "w-full",
                "p-4",
                "border",
                "border-gray-200",
                "bg-gray-50",
                "rounded-b-xl",
                "dark:border-gray-600",
                "dark:bg-gray-700"
            )
        }) {
            content()
        }
    }
}