package fi.bullpen.kmpapp.composables

import androidx.compose.runtime.Composable
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthToken
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.ElementBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.w3c.dom.HTMLElement


@Composable
fun TelegramLoginWidget(botName: String, onTelegramAuth: (user: TelegramUserAuthToken) -> Unit) {

    window.asDynamic().onTelegramAuth = { user: dynamic ->
        onTelegramAuth(
            TelegramUserAuthToken(
                authDate = (user.auth_date as Double).toULong(),
                firstName = user.first_name as String,
                lastName = user.last_name as String?,
                hash = user.hash as String,
                id = (user.id as Double).toULong(),
                photoUrl = user.photo_url as String?,
                username = user.username as String?,
            )
        )
    }

    TagElement(elementBuilder = ElementBuilder.createBuilder<HTMLElement>("script"), applyAttrs = {
        attr("src", "https://telegram.org/js/telegram-widget.js?22")
        attr("async", "")
        attr("data-telegram-login", botName)
        attr("data-size", "large")
        attr("data-onauth", "onTelegramAuth(user)")
        attr("data-request-access", "write")
    }, content = {})
}