package fi.bullpen.kmpapp.screens.turnkeyManagement.composeables

import androidx.compose.runtime.Composable
import fi.bullpen.kmpapp.screens.generic.UserActionState
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement


@Composable
fun ErrorMessageCard(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    managementScreenUserActionState: UserActionState,
    onClearError: () -> Unit
) {
    if (managementScreenUserActionState is UserActionState.Error) {
        Div({
            classes(
                "bg-red-100", "border", "border-red-400", "text-red-700", "px-4", "py-3", "rounded", "relative"
            )
            attrs?.invoke(this)
            attr("role", "alert")
        }) {
            CloseButtonError(onClearError)
            Div({
                classes("font-bold")
            }) {
                Text("Error:")
            }
            Span({
                classes("block", "sm:inline")
            }) {
                Text(managementScreenUserActionState.message)
            }
        }
    }
}
