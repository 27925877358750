package fi.bullpen.kmpapp.service.webauthn

import kotlinx.serialization.Serializable
import kotlinx.serialization.SerialName

@Serializable
data class V1Attestation(
    val credentialId: String,
    val clientDataJson: String,
    val attestationObject: String,
    val transports: List<V1AuthenticatorTransport>
)

@Serializable
enum class V1AuthenticatorTransport {
    @SerialName("AUTHENTICATOR_TRANSPORT_BLE") BLE,
    @SerialName("AUTHENTICATOR_TRANSPORT_INTERNAL") INTERNAL,
    @SerialName("AUTHENTICATOR_TRANSPORT_NFC") NFC,
    @SerialName("AUTHENTICATOR_TRANSPORT_USB") USB,
    @SerialName("AUTHENTICATOR_TRANSPORT_HYBRID") HYBRID
}
