package fi.bullpen.kmpapp.data.turnkey.dto

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
@SerialName("apiKey")
data class ApiKey(
    val credential: Credential,
    @SerialName("apiKeyId") val apiKeyId: String,
    @SerialName("apiKeyName") val apiKeyName: String,
    val createdAt: DateTime,
    val updatedAt: DateTime,
    @SerialName("expirationSeconds") val expirationSeconds: String? = null
) {
    @OptIn(FormatStringsInDatetimeFormats::class)
    @Transient
    private val dateTimeFormat = LocalDateTime.Format {
        byUnicodePattern("yy-MM-dd HH:mm")
    }

    @Transient
    val displayExpiresAt: String = fun(): String {
        val createdAtLong = createdAt.seconds.toLong()
        val expirationSecondsLong = expirationSeconds?.toLong() ?: return "Never"
        val expirationTime = createdAtLong + expirationSecondsLong
        return dateTimeFormat.format(
            Instant.fromEpochSeconds(expirationTime)
                .toLocalDateTime(TimeZone.currentSystemDefault())
        )
    }()
}

@Serializable
data class Credential(
    @SerialName("publicKey") val publicKey: String, @SerialName("type") val credentialType: String
)

@Serializable
data class DateTime(
    val seconds: String, val nanos: String
)
