package fi.bullpen.kmpapp.screens.turnkeyManagement

import fi.bullpen.kmpapp.data.turnkey.dto.ApiKey
import fi.bullpen.kmpapp.data.turnkey.dto.TurnkeyUser
import fi.bullpen.kmpapp.data.turnkey.walletManagementSessionApiKeyName
import fi.bullpen.kmpapp.screens.generic.UserActionState

data class TurnkeyManagementScreenState(
    val telegramBotName: String,
    val organizationId: String? = null,
    val user: TurnkeyUser? = null,
    private val bullpenPublicApiKeys: List<ApiKey> = emptyList(),
    val modifyApiKeysState: UserActionState = UserActionState.Available,
    val modifyAuthenticatorsState: UserActionState = UserActionState.Available,
    val navigationState: ManagementScreenNavigationState = ManagementScreenNavigationState.Available
) {
    val apiKeyStates: List<BullpenApiKeyState> = user?.apiKeys.orEmpty().filter {
        it.apiKeyName != walletManagementSessionApiKeyName
    }.map {
        BullpenApiKeyState(it, true)
    }.toMutableList().apply {
        addMissingBullpenApiKeys(this, bullpenPublicApiKeys)
    }

    val authenticators = user?.authenticators.orEmpty()

    private fun addMissingBullpenApiKeys(
        userApiKeys: MutableList<BullpenApiKeyState>, bullpenPublicApiKeys: List<ApiKey>
    ) {
        bullpenPublicApiKeys.forEach { bullpenApiKey ->
            if (userApiKeys.none { it.apiKey.credential.publicKey == bullpenApiKey.credential.publicKey }) {
                userApiKeys.add(BullpenApiKeyState(bullpenApiKey, false))
            }
        }
    }
}

sealed interface ManagementScreenNavigationState {
    data object Available : ManagementScreenNavigationState
    sealed interface ExitAction : ManagementScreenNavigationState
    data object LogOutOfDashboard : ExitAction
    data object ExitToTelegram : ExitAction
}


data class BullpenApiKeyState(
    val apiKey: ApiKey,
    val enabled: Boolean = false,
)
