package fi.bullpen.kmpapp.data.turnkey.dto

import kotlinx.serialization.Serializable

@Serializable
data class TurnkeyUserResponse(
    val user: TurnkeyUser
)

@Serializable
data class TurnkeyUser(
    val userId: String,
    val userName: String,
    val userEmail: String?,
    val authenticators: List<Authenticator>,
    val apiKeys: List<ApiKey>,
    val userTags: List<String>,
    val createdAt: DateTime,
    val updatedAt: DateTime,
)