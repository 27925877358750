package fi.bullpen.kmpapp.data.bullpen

import fi.bullpen.kmpapp.data.bullpen.dto.InitUserEmailRecoveryRequestBody
import fi.bullpen.kmpapp.data.bullpen.dto.SetUserEmailAndInitRecoveryRequestBody
import fi.bullpen.kmpapp.data.bullpen.responses.JwtToken
import fi.bullpen.kmpapp.data.bullpen.responses.LoginResponse
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthTokenRepository
import fi.bullpen.kmpapp.data.turnkey.dto.ApiKey
import fi.bullpen.kmpapp.data.utils.HttpHeader
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.io.encoding.Base64

interface BullpenApi {
    var jwtToken: JwtToken?

    suspend fun getPublicBullpenTurnkeyApiKeys(): List<ApiKey>
    suspend fun setTurnkeyUserEmailAndInitRecovery(
        email: String, iframePublicKey: String
    )

    suspend fun emailRecovery(iframeStamperPublicKey: String)
    suspend fun authenticateTelegram(): LoginResponse
}


@Serializable
private data class SignedTurnkeyRequest(
    val turnkeyEndpoint: String,
    val body: String,
    val stampHttpRequestHeader: HttpHeader,
)

class KtorBullpenApi(
    private val client: HttpClient,
    private val telegramUserAuthRepo: TelegramUserAuthTokenRepository,
    private val apiUrl: String
) : BullpenApi {
    override var jwtToken: JwtToken? = null

    override suspend fun authenticateTelegram(): LoginResponse {
        val user = telegramUserAuthRepo.get() ?: throw Error("User not authenticated w/ Telegram")
        val response: HttpResponse = client.post("$apiUrl/authenticate-telegram") {
            header(
                "X-Telegram-Auth", Base64.UrlSafe.encode(Json.encodeToString(user).encodeToByteArray()).trimEnd('=')
            )
        }
        return when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {
                response.body()
            }

            else -> {
                throw Error("$status Error${if (status.description.isNotEmpty()) ": ${status.description}" else ""}")
            }
        }
    }

    override suspend fun getPublicBullpenTurnkeyApiKeys(): List<ApiKey> {
        val jwtToken = jwtToken ?: throw Error("User not authenticated for user email recovery setup")

        val response: HttpResponse = client.get("$apiUrl/turnkey/api-key/bullpen-public") {
            header(
                HttpHeaders.Authorization, "Bearer $jwtToken"
            )
        }
        return when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {
                response.body()
            }

            else -> {
                throw Error("$status Error${if (status.description.isNotEmpty()) ": ${status.description}" else ""}")
            }
        }
    }

    override suspend fun setTurnkeyUserEmailAndInitRecovery(
        email: String, iframePublicKey: String
    ) {
        val jwtToken = jwtToken ?: throw Error("User not authenticated for user email recovery setup")

        val response: HttpResponse = client.post("$apiUrl/turnkey/set-user-email-and-init-recovery") {
            header(
                HttpHeaders.Authorization, "Bearer $jwtToken"
            )
            setBody(
                SetUserEmailAndInitRecoveryRequestBody(
                    email = email,
                    targetPublicKey = iframePublicKey,
                )
            )
        }
        return when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {
                response.body()
            }

            else -> {
                val body = response.bodyAsText()
                throw Error("$status Error: ${body.ifEmpty { "No response body" }}")
            }
        }
    }

    override suspend fun emailRecovery(iframeStamperPublicKey: String) {
        val jwtToken = jwtToken ?: throw Error("User not authenticated for user email recovery")

        val response: HttpResponse = client.post("$apiUrl/turnkey/init-user-email-recovery") {
            header(
                HttpHeaders.Authorization, "Bearer $jwtToken"
            )
            setBody(
                InitUserEmailRecoveryRequestBody(
                    targetPublicKey = iframeStamperPublicKey,
                )
            )
        }
        when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {
                return response.body()
            }

            else -> {
                val body = response.bodyAsText()
                throw Error("$status Error: ${body.ifEmpty { "No response body" }}")
            }
        }
    }

}
