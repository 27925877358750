package fi.bullpen.kmpapp.screens.turnkeyRecovery

import cafe.adriel.voyager.core.model.StateScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import dev.whyoleg.cryptography.CryptographyProvider
import dev.whyoleg.cryptography.algorithms.digest.SHA256
import fi.bullpen.kmpapp.Config
import fi.bullpen.kmpapp.data.bullpen.BullpenApi
import fi.bullpen.kmpapp.data.bullpen.UserInfoRepository
import fi.bullpen.kmpapp.data.turnkey.TurnkeyApi
import fi.bullpen.kmpapp.data.turnkey.TurnkeyAuthIframeStamper
import fi.bullpen.kmpapp.data.turnkey.dto.RecoverUserIntent
import fi.bullpen.kmpapp.screens.generic.UserActionState
import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock


class TurnkeyRecoveryScreenModel(
    private val bullpenApi: BullpenApi,
    private val turnkeyApi: TurnkeyApi,
    private val userInfoRepository: UserInfoRepository,
    config: Config
) : StateScreenModel<TurnkeyRecoveryScreenState>(TurnkeyRecoveryScreenState(config.botName)) {
    val hasher = CryptographyProvider.Default.get(SHA256).hasher()

    override fun onDispose() {
        super.onDispose()
        state.value.iframeStamper?.clear()
    }

    suspend fun setIframeStamper(stamper: IframeStamper) {
        stamper.init()
        mutableState.update {
            it.copy(iframeStamper = stamper)
        }
        initRecovery()
    }

    /**
     * This function looks up whether a given email is registered with our backend
     * If it is registered, it initializes recovery with Turnkey, which triggers an email
     * If it isn't, an error is returned.
     */
    private fun initRecovery() {
        if (state.value.recoveryState !is UserActionState.AwaitingAction) return

        val iframeStamper = when (val iframeStamper = state.value.iframeStamper) {
            null -> {
                mutableState.update {
                    it.copy(recoveryState = UserActionState.Error("cannot perform initRecovery without an iframeStamper"))
                }
                return
            }

            else -> {
                mutableState.update {
                    it.copy(recoveryState = UserActionState.InProgress)
                }
                iframeStamper
            }
        }

        screenModelScope.launch {
            val timeHash = hasher.hash(Clock.System.now().epochSeconds.toString().encodeToByteArray()).toHexString()
            val timeHashLast4 = timeHash.takeLast(4)
            val defaultPasskeyName = "Bullpen Passkey #$timeHashLast4"
            try {
                bullpenApi.emailRecovery(iframeStamper.iframePublicKey!!)
                val usersTurnkeyInfo = userInfoRepository.get()!!.usersTurnkeyInfo
                mutableState.update {
                    it.copy(
                        recoveryState = UserActionState.Available,
                        usersTurnkeyInfo = usersTurnkeyInfo,
                        defaultPasskeyName = defaultPasskeyName
                    )
                }
            } catch (t: Throwable) {
                Logger.e(t) { "Error in initRecovery" }
                mutableState.update {
                    it.copy(recoveryState = UserActionState.Error(t.message ?: "An error occurred"))
                }
            }
        }
    }

    fun createNewAuthenticator(recoveryCode: String, authenticatorName: String) {
        val iframeStamper = when (val iframeStamper = state.value.iframeStamper) {
            null -> {
                mutableState.update {
                    it.copy(recoveryState = UserActionState.Error("cannot perform initRecovery without an iframeStamper"))
                }
                return
            }

            else -> {
                mutableState.update {
                    it.copy(recoveryState = UserActionState.InProgress)
                }
                iframeStamper
            }
        }
        screenModelScope.launch {
            try {
                iframeStamper.injectCredentialBundle(recoveryCode.trim())
                val usersTurnkeyInfo = userInfoRepository.get()!!.usersTurnkeyInfo

                turnkeyApi.recover(
                    TurnkeyAuthIframeStamper(iframeStamper), usersTurnkeyInfo.organizationId, RecoverUserIntent(
                        turnkeyApi.createTurnkeyWebauthnAuthenticator(authenticatorName), usersTurnkeyInfo.userId
                    )
                )
                mutableState.update {
                    it.copy(
                        recoveryState = UserActionState.Available, successfulRecovery = true
                    )
                }
            } catch (t: Throwable) {
                mutableState.update {
                    it.copy(recoveryState = UserActionState.Error(t.message ?: "An error occurred"))
                }
            }
        }

    }

}
