package fi.bullpen.kmpapp.service.webauthn

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.io.encoding.Base64.Default.UrlSafe

@Serializable
data class CredentialCreationOptions(val publicKey: PublicKeyCredentialCreationOptions)

@Serializable
data class CredentialRequestOptions(val publicKey: PublicKeyCredentialRequestOptions)

@Serializable
data class PublicKeyCredentialCreationOptions(
    val rp: RelyingParty,
    @Serializable(with = Base64Serializer::class) val challenge: ByteArray,
    val pubKeyCredParams: Array<PublicKeyCredentialParameter>,
    val user: User,
    val authenticatorSelection: AuthenticatorSelection,
    val timeout: ULong? = null,
)

@Serializable
data class PublicKeyCredentialRequestOptions(
    val rpId: String,
    @Serializable(with = Base64Serializer::class) val challenge: ByteArray,
    val allowCredentials: Array<PublicKeyCredentialDescriptor>,
    val timeout: ULong,
    val userVerification: UserVerificationRequirement
)

@Serializable
data class RelyingParty(
    val id: String, val name: String
)

@Serializable
data class PublicKeyCredentialParameter(
    val type: String, val alg: Int
)

@Serializable
data class User(
    @Serializable(with = Base64Serializer::class) val id: ByteArray, val name: String, val displayName: String
)

@Serializable
data class AuthenticatorSelection(
    val authenticatorAttachment: String,
    val requireResidentKey: Boolean, val residentKey: String, val userVerification: String
)

@Serializable
data class PublicKeyCredentialDescriptor(
    val type: String,
    @Serializable(with = Base64Serializer::class) val id: ByteArray,
    val transports: Array<AuthenticatorTransport>
)

@Serializable
enum class PublicKeyCredentialType {
    @SerialName("public-key")
    PUBLIC_KEY
}


@Serializable
enum class UserVerificationRequirement {
    @SerialName("required")
    REQUIRED,

    @SerialName("preferred")
    PREFERRED,

    @SerialName("discouraged")
    DISCOURAGED
}

@Serializable
enum class AuthenticatorTransport {
    @SerialName("usb")
    USB,

    @SerialName("nfc")
    NFC,

    @SerialName("ble")
    BLE,

    @SerialName("internal")
    INTERNAL
}


object Base64Serializer : KSerializer<ByteArray> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Base64", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: ByteArray) {
        val base64 = UrlSafe.encode(value).trimEnd('=')
        encoder.encodeString(base64)
    }

    override fun deserialize(decoder: Decoder): ByteArray {
        val string = decoder.decodeString()
        return UrlSafe.decode(string)
    }
}
