package fi.bullpen.kmpapp.screens.utils

import fi.bullpen.kmpapp.data.utils.HttpHeader
import js.turnkey.iframeStamper.TurnkeyIframeStamper
import kotlinx.coroutines.await
import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper as CommonIframeStamper

class WebIframeStamper(private val iframeStamper: TurnkeyIframeStamper.IframeStamper) : CommonIframeStamper {
    override val iframePublicKey: String?
        get() = iframeStamper.iframePublicKey

    override suspend fun init(): String {
        return iframeStamper.init().await()
    }

    override suspend fun injectCredentialBundle(bundle: String): Boolean {
        return iframeStamper.injectCredentialBundle(bundle).await()
    }

    override fun clear() {
        iframeStamper.clear()
    }

    override suspend fun stamp(body: String): HttpHeader {
        val stamp = iframeStamper.stamp(body).await()
        return HttpHeader(stamp.stampHeaderName, stamp.stampHeaderValue)
    }

}