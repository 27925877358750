package fi.bullpen.kmpapp.data.turnkey.dto

import kotlinx.serialization.Serializable

@Serializable
data class CreateApiKeysIntent(
    val userId: String, val apiKeys: List<ApiKeyParams>
) : Intent {
    @Serializable
    data class ApiKeyParams(
        val apiKeyName: String,
        val publicKey: String,
        val expirationSeconds: String? = null,
    )
}
