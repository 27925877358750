package fi.bullpen.kmpapp.screens.auth

import cafe.adriel.voyager.core.model.StateScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import fi.bullpen.kmpapp.Config
import fi.bullpen.kmpapp.data.bullpen.BullpenApi
import fi.bullpen.kmpapp.data.bullpen.UserInfo
import fi.bullpen.kmpapp.data.bullpen.UserInfoRepository
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthToken
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthTokenRepository
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

class AuthScreenModel(
    private val bullpenApi: BullpenApi,
    private val telegramUserAuthTokenRepository: TelegramUserAuthTokenRepository,
    private val userInfoRepository: UserInfoRepository,
    config: Config
) : StateScreenModel<AuthScreenState>(AuthScreenState(config.botName)) {

    fun onTelegramAuth(user: TelegramUserAuthToken) = screenModelScope.launch {
        mutableState.update { it.copy(authState = AuthState.InProgress) }
        telegramUserAuthTokenRepository.save(user)
        val response = try {
            bullpenApi.authenticateTelegram()
        } catch (e: Throwable) {
            mutableState.update { it.copy(authState = AuthState.Error(e.message ?: "Unknown error")) }
            return@launch
        }
        bullpenApi.jwtToken = response.token
        val userId = response.token.claims.sub
        userInfoRepository.save(UserInfo(userId, response.usersTurnkeyInfo))
        mutableState.update { it.copy(authState = AuthState.SignedIn) }
    }

}
