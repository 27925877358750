package fi.bullpen.kmpapp.data.bullpen.responses

import io.ktor.util.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.jvm.JvmInline

@Serializable
data class LoginResponse(
    val token: JwtToken,
    val usersTurnkeyInfo: UsersTurnkeyInfo
)

@Serializable
@JvmInline
value class JwtToken(private val value: String) {
    val claims: JwtClaims
        get() {
            val (_, payload, _) = value.split('.')
            return Json.decodeFromString(
                JwtClaims.serializer(),
                payload.decodeBase64String()
            )
        }

    override fun toString(): String = value
}

// Kotlin enum corresponding to the Rust enum
@Serializable
enum class ClaimsPermission {
    InitEmailRecovery, EmailRecovery, BullpenUserReadApis
}

@Serializable
data class JwtClaims(
    val sub: String, val exp: ULong, val permissions: List<ClaimsPermission>
)
