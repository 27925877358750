package fi.bullpen.kmpapp.data.turnkey.dto

import kotlinx.serialization.Serializable

@Serializable
data class TurnkeyActivityRequestBody<I>(
    val type: String,
    val timestampMs: String,
    val organizationId: String,
    val parameters: I,
)

