package fi.bullpen.kmpapp.composables

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.svg.*
import org.w3c.dom.svg.SVGElement

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun WarningIcon(attrs: AttrBuilderContext<SVGElement>? = null) {
    Svg(viewBox = "0 0 24 24", attrs = {
        attr("aria-hidden", "true")
        xmlns("http://www.w3.org/2000/svg")
        fill("none")
        viewBox("0 0 20 20")
        attrs?.invoke(this)
    }) {
        Path(d = "M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z", attrs = {
            attr("stroke", "currentColor")
            attr("stroke-linecap", "round")
            attr("stroke-linejoin", "round")
            attr("stroke-width", "2")
        })
    }
}