import fi.bullpen.kmpapp.App
import fi.bullpen.kmpapp.Config
import fi.bullpen.kmpapp.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.renderComposable


@JsModule("./globals.css")
@JsNonModule
external val cssFile: dynamic


suspend fun main() {
    cssFile

    val response = window.fetch("/config").await()
    require(response.ok) { "Failed to fetch config: " + response.text().await() }
    val responseText = response.text().await()
    println(responseText)
    val ignoreUnknownJson = Json { ignoreUnknownKeys = true }
    val config: Config = ignoreUnknownJson.decodeFromString(responseText)
    initKoin(config)


//    val urlParams = getUrlParams()
//    if (urlParams.contains("auth_date") &&
//        urlParams.contains("first_name") &&
//        urlParams.contains("hash") &&
//        urlParams.contains("id")
//    ) {
//        val telegramUserAuthToken = TelegramUserAuthToken(
//            authDate = urlParams["auth_date"]!!.toULong(),
//            firstName = urlParams["first_name"]!!,
//            lastName = urlParams["last_name"],
//            hash = urlParams["hash"]!!,
//            id = urlParams["id"]!!.toULong(),
//            photoUrl = urlParams["photo_url"],
//            username = urlParams["username"]
//        )
//        deleteAllCookies()
//        document.cookie = "tg_user=${Json.encodeToString(telegramUserAuthToken)}"
//    }
//    console.log("Cookies:", document.cookie)


    renderComposable(rootElementId = "root") {
        App()
    }
}


fun deleteAllCookies() {
    val cookies = document.cookie.split(";")
    cookies.forEach { cookie ->
        val eqPos = cookie.indexOf("=")
        val name = if (eqPos > -1) cookie.substring(0, eqPos) else cookie
        document.cookie = "$name=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/"
    }
}
