package fi.bullpen.kmpapp.screens.home

import cafe.adriel.voyager.core.model.StateScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import dev.whyoleg.cryptography.algorithms.asymmetric.EC
import fi.bullpen.kmpapp.Config
import fi.bullpen.kmpapp.data.bullpen.BullpenApi
import fi.bullpen.kmpapp.data.bullpen.UserInfoRepository
import fi.bullpen.kmpapp.data.bullpen.responses.ClaimsPermission
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthTokenRepository
import fi.bullpen.kmpapp.data.turnkey.TurnkeyApi
import fi.bullpen.kmpapp.data.turnkey.TurnkeyApiKeyRepository
import fi.bullpen.kmpapp.data.turnkey.TurnkeyAuthWebauthnStamper
import fi.bullpen.kmpapp.data.turnkey.dto.CreateApiKeysIntent
import fi.bullpen.kmpapp.data.turnkey.walletManagementSessionApiKeyName
import fi.bullpen.kmpapp.data.utils.compressECDSAPublicKey
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.minutes
import kotlin.time.DurationUnit

class HomeScreenModel(
    private val bullpenApi: BullpenApi,
    private val turnkeyApi: TurnkeyApi,
    private val webauthnStamper: TurnkeyAuthWebauthnStamper,
    private val turnkeyApiKeyRepository: TurnkeyApiKeyRepository,
    private val userInfoRepository: UserInfoRepository,
    telegramUserAuthTokenRepository: TelegramUserAuthTokenRepository,
    config: Config
) : StateScreenModel<HomeScreenState>(
    HomeScreenState(
        config.botName,
        telegramUserAuthToken = telegramUserAuthTokenRepository.get()
            ?: throw IllegalStateException("User not logged in with Telegram")
    )
) {

    fun onInit() = screenModelScope.launch {
        val jwtToken = bullpenApi.jwtToken
        if (jwtToken == null) {
            mutableState.update { it.copy(authState = AuthState.Error("Invalid State: User not logged in")) }
            return@launch
        }
        when {
            jwtToken.claims.permissions.contains(ClaimsPermission.InitEmailRecovery) -> {
                mutableState.update { it.copy(emailRecoverySetupNeeded = true) }
            }

            jwtToken.claims.permissions.contains(ClaimsPermission.EmailRecovery) -> {
                mutableState.update { it.copy(authState = AuthState.PasskeyLoginNeeded) }
            }
        }
    }

    fun onLogin() {
        screenModelScope.launch {
            mutableState.update { it.copy(authState = AuthState.InProgress) }
            try {
                turnkeyApiKeyRepository.refresh()
                val newApiKey = turnkeyApiKeyRepository.get()!!
                val pubKeyHex =
                    newApiKey.publicKey.encodeTo(EC.PublicKey.Format.RAW).compressECDSAPublicKey().toHexString()
                val expiresIn = 30.minutes.toInt(DurationUnit.SECONDS).toString()
                val usersTurnkeyInfo = userInfoRepository.get()!!.usersTurnkeyInfo
                val createApiKeysIntent = CreateApiKeysIntent(
                    usersTurnkeyInfo.userId, listOf(
                        CreateApiKeysIntent.ApiKeyParams(
                            walletManagementSessionApiKeyName, pubKeyHex, expiresIn
                        )
                    )
                )
                turnkeyApi.createApiKeys(
                    webauthnStamper, usersTurnkeyInfo.organizationId, createApiKeysIntent
                )
                mutableState.update { it.copy(authState = AuthState.SignedIn) }
            } catch (e: Throwable) {
                e.printStackTrace()
                mutableState.update { it.copy(authState = AuthState.Error(e.message ?: "Unknown error")) }
            }
        }
    }

}
