package fi.bullpen.kmpapp.screens.turnkeyManagement

import androidx.compose.runtime.*
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import fi.bullpen.kmpapp.composables.*
import fi.bullpen.kmpapp.data.turnkey.dto.ApiKey
import fi.bullpen.kmpapp.data.turnkey.dto.Authenticator
import fi.bullpen.kmpapp.data.turnkey.dto.TurnkeyUser
import fi.bullpen.kmpapp.screens.generic.UserActionState
import fi.bullpen.kmpapp.screens.home.HomeScreen
import fi.bullpen.kmpapp.screens.turnkeyManagement.composeables.*
import fi.bullpen.kmpapp.screens.utils.getScreenModel
import org.jetbrains.compose.web.dom.*

data object TurnkeyManagementScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val screenModel: TurnkeyManagementScreenModel = getScreenModel()
        val screenState by screenModel.state.collectAsState()

        when (screenState.navigationState) {
            ManagementScreenNavigationState.LogOutOfDashboard -> {
                navigator.replace(HomeScreen)
            }

            ManagementScreenNavigationState.ExitToTelegram -> {
                getBackToTelegramCallback(screenState.telegramBotName)()
            }

            else -> {}
        }

        LaunchedEffect(Unit) {
            screenModel.onInit()
        }
        Scaffold(
            screenState.telegramBotName,
            onClickBackToTelegram = {
                screenModel.onExit(ManagementScreenNavigationState.ExitToTelegram)
            }
        ) {
            Img(src = "/bullpen-bull-black-bg.png", alt = "Bullpen Logo") {
                classes("mx-auto", "mt-24", "mb-12")
                attr("width", "80")
                attr("height", "80")
            }

//            UserInfoSection(screenState.organizationId, screenState.user)
//            AccountRecoverySection(screenState.user, onSetEmail = {
//                screenModel.setUserEmail(it)
//            })

            ApiKeysSection(screenState.apiKeyStates, screenState.modifyApiKeysState, onAdd = {
                screenModel.createApiKey(it)
            }, onDelete = {
                screenModel.deleteApiKeys(listOf(it), true)
            }, onClearError = {
                screenModel.clearModifyApiKeysStateError()
            })

            PasskeysSection(screenState.authenticators, screenState.modifyAuthenticatorsState, onDelete = {
                screenModel.deleteAuthenticator(it)
            }, onCreateNew = {
                screenModel.createAuthenticator()
            }, onClearError = {
                screenModel.clearModifyAuthenticatorsStateError()
            })

            Div({ classes("w-full", "text-center", "mt-6") }) {
                A(href = "/", {
                    classes("block", "text-inherit", "no-underline")
                    onClick { event ->
                        event.preventDefault()
                        screenModel.onExit(ManagementScreenNavigationState.LogOutOfDashboard)
                    }
                }) {
                    Text("Logout")
                }
            }
        }
    }
}

@Composable
private fun UserInfoSection(organizationId: String?, user: TurnkeyUser?) {
    PageSection("Turnkey User Info") {
        P({ classes("font-semibold", "mr-7") }) { Text("Organization ID") }
        P({ classes("text-xs", "text-zinc-600", "mb-1") }) { Text(organizationId ?: "N/A") }
        P({ classes("font-semibold", "mr-7") }) { Text("User ID") }
        P({ classes("text-xs", "text-zinc-600", "mb-1") }) { Text(user?.userId ?: "N/A") }
        P({ classes("font-semibold", "mr-7") }) { Text("Username") }
        P({ classes("text-xs", "text-zinc-600", "mb-1") }) { Text(user?.userName ?: "N/A") }
    }
}

@Composable
private fun AccountRecoverySection(user: TurnkeyUser?, onSetEmail: (String) -> Unit) {
    var showInputEmailModal by remember { mutableStateOf(false) }
    if (showInputEmailModal) {
        EmailInputModal(user?.userEmail, { email ->
            onSetEmail(email)
            showInputEmailModal = false
        }, {
            showInputEmailModal = false
        })
    }
    PageSection("Account Recovery") {

        when (val email = user?.userEmail) {
            null -> {
                Div({ classes("flex", "flex-row", "items-center", "mt-2", "mb-1") }) {
                    P({ classes("font-semibold") }) { Text("Email") }
                    WarningIcon { classes("text-red-600", "w-5", "h-5", "mx-1") }
                }
                Div({ classes("flex", "flex-row", "mb-1") }) {
                    P({ classes("text-xs", "text-zinc-600") }) { Text("N/A") }
                }
            }

            else -> {
                P({ classes("font-semibold", "mr-7") }) { Text("Email") }
                P({ classes("text-xs", "text-zinc-600", "mb-1") }) { Text(email) }
            }
        }

        val buttonLabel = if (user?.userEmail == null) "Add Email" else "Change Email"
        Div({ classes("flex", "flex-row", "items-baseline") }) {
            Button({
                classes(
                    "py-2.5",
                    "px-5",
                    "text-sm",
                    "font-medium",
                    "text-gray-900",
                    "focus:outline-none",
                    "bg-white",
                    "rounded-lg",
                    "border",
                    "border-gray-200",
                    "hover:text-green-700",
                    "focus:z-10",
                    "focus:ring-4",
                    "focus:ring-gray-100",
                    "dark:focus:ring-gray-700",
                    "dark:bg-gray-800",
                    "dark:text-gray-400",
                    "dark:border-gray-600",
                    "dark:hover:text-white",
                    "dark:hover:bg-gray-700"
                )
                onClick {
                    showInputEmailModal = true
                }
            }) { Text(buttonLabel) }
            if (user?.userEmail == null) {
                P({
                    classes(
                        "ml-2", "text-sm", "text-red-600"
                    )
                }) { Text("Bullpen requires all users set an email to enable self-custodial wallet recovery.") }
            }
        }
    }
}


@Composable
private fun ApiKeysSection(
    apiKeyStates: List<BullpenApiKeyState>,
    modifyApiKeysState: UserActionState,
    onAdd: (ApiKey) -> Unit,
    onDelete: (ApiKey) -> Unit,
    onClearError: () -> Unit
) {
    var apikeyPendingDeletion: BullpenApiKeyState? by remember { mutableStateOf(null) }
    val showConfirmDeleteModal by derivedStateOf { apikeyPendingDeletion != null }
    if (showConfirmDeleteModal) {
        ConfirmDeletionModal("This will revoke Bullpen's permission to sign transactions on your behalf and disable your ability to trade.",
            onCancel = {
                apikeyPendingDeletion = null
            },
            onConfirm = {
                apikeyPendingDeletion?.let { onDelete(it.apiKey) }
                apikeyPendingDeletion = null
            })
    }
    PageSection(
        "Delegated Transaction Approvals", isLoading = modifyApiKeysState is UserActionState.InProgress
    ) {
        val disableInteraction = if (modifyApiKeysState !is UserActionState.AwaitingAction) {
            arrayOf("pointer-events-none")
        } else {
            emptyArray()
        }
        Div({ classes("grid", "grid-cols-2", "sm:grid-cols-3", "gap-4", *disableInteraction) }) {
            apiKeyStates.forEach { state ->
                Div({
                    classes(
                        "flex",
                        "flex-col",
                        "rounded-lg",
                        "border",
                        "border-gray-300",
                        "py-2",
                        "px-3",
                        "relative",
                        "hover:bg-gray-100",
                        "cursor-pointer"
                    )
                    onClick {
                        // Handle API key selection or other action
                    }
                }) {
                    P({ classes("font-semibold", "mr-7") }) { Text("${state.apiKey.apiKeyName}") }
                    P({ classes("text-xs", "mt-1", "text-zinc-600") }) { Text("id:${state.apiKey.apiKeyId}") }
                    P({ classes("text-zinc-500", "font-light", "text-xs") }) {
                        Text("Expires: ${state.apiKey.displayExpiresAt}")
                    }
                    Div({ classes("mt-auto") }) {
                        EnabledButton(state.enabled) {
                            when (state.enabled) {
                                true -> apikeyPendingDeletion = state
                                false -> onAdd(state.apiKey)
                            }
                        }
                    }
                }
            }
        }
        ErrorMessageCard({ classes("mt-2") }, modifyApiKeysState, onClearError)
    }
}

@Composable
private fun PasskeysSection(
    passkeys: List<Authenticator>,
    modifyAuthenticatorsState: UserActionState,
    onDelete: (Authenticator) -> Unit,
    onCreateNew: () -> Unit,
    onClearError: () -> Unit
) {
    var passkeyPendingDeletion: Authenticator? by remember { mutableStateOf(null) }
    val showConfirmDeleteModal by derivedStateOf { passkeyPendingDeletion != null }
    if (showConfirmDeleteModal) {
        ConfirmDeletionModal("This action cannot be undone.", onCancel = {
            passkeyPendingDeletion = null
        }, onConfirm = {
            passkeyPendingDeletion?.let { onDelete(it) }
            passkeyPendingDeletion = null
        })
    }
    PageSection("Passkeys") {
        val itemClasses = arrayOf(
            "rounded-lg", "border", "border-gray-300", "py-2", "px-3", "relative", "hover:bg-gray-100", "cursor-pointer"
        )
        val disableInteraction = if (modifyAuthenticatorsState !is UserActionState.AwaitingAction) {
            arrayOf("pointer-events-none")
        } else {
            emptyArray()
        }
        Div({ classes("grid", "grid-cols-2", "sm:grid-cols-3", "gap-4", *disableInteraction) }) {
            passkeys.forEach { passkey ->
                Div({
                    classes(*itemClasses)
                    onClick {
                        // Handle API key selection or other action
                    }
                }) {
                    if (passkeys.size > 1) {
                        CloseButtonStd {
                            passkeyPendingDeletion = passkey
                        }
                    }
                    P({ classes("font-semibold", "mr-7") }) { Text(passkey.authenticatorName) }
                    P({ classes("text-xs", "mt-1", "text-zinc-600") }) { Text("model: ${passkey.model}") }
                    P({ classes("text-xs", "mt-1", "text-zinc-600") }) { Text("id:${passkey.authenticatorId}") }
                    P({ classes("text-zinc-500", "font-light", "text-xs", "mt-4", "text-right") }) {
                        Text("Created At: ${passkey.displayCreatedAt}")
                    }
                }
            }
            Div({
                classes(*itemClasses)
                classes("flex", "items-center", "justify-center")
                onClick {
                    // Handle API key selection or other action
                    onCreateNew()
                }
            }) {
                when (modifyAuthenticatorsState) {
                    is UserActionState.AwaitingAction -> {
                        PlusIcon { classes("text-gray-400", "w-20", "h-20", "dark:text-gray-200") }
                    }

                    is UserActionState.InProgress -> ProgressSpinner { classes("w-20", "h-20") }
                }
            }
        }
        ErrorMessageCard({ classes("mt-2") }, modifyAuthenticatorsState, onClearError)
    }
}
