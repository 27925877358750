package fi.bullpen.kmpapp.data.telegram

interface TelegramUserAuthTokenRepository {
    fun save(telegramUserAuthToken: TelegramUserAuthToken)
    fun get(): TelegramUserAuthToken?
}

class InMemoryTelegramUserAuthTokenRepository : TelegramUserAuthTokenRepository {
    private var telegramUserAuthToken: TelegramUserAuthToken? = null

    override fun save(telegramUserAuthToken: TelegramUserAuthToken) {
        this.telegramUserAuthToken = telegramUserAuthToken
    }

    override fun get(): TelegramUserAuthToken? {
        return telegramUserAuthToken
    }
}