package fi.bullpen.kmpapp.service.webauthn

data class PublicKeyCredential<R>(
    val rawId: ByteArray,
    val response: R,
    val authenticatorAttachment: String,
    val id: String,
    val type: String
)

interface AuthenticatorResponse {
    val clientDataJSON: ByteArray
}

data class AuthenticatorAttestationResponse(
    override val clientDataJSON: ByteArray,
    val attestationObject: ByteArray,
    val transports: List<V1AuthenticatorTransport>
) : AuthenticatorResponse

data class AuthenticatorAssertionResponse(
    override val clientDataJSON: ByteArray,
    val authenticatorData: ByteArray,
    val signature: ByteArray,
    val userHandle: ByteArray,
) : AuthenticatorResponse