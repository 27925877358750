package fi.bullpen.kmpapp.screens.auth

data class AuthScreenState(
    val telegramBotName: String,
    val authState: AuthState = AuthState.SignedOut,
)

sealed interface AuthState {
    sealed interface AwaitingAction : AuthState
    data object SignedOut : AuthState
    data object InProgress : AuthState
    data class Error(val message: String) : AwaitingAction
    data object SignedIn : AuthState
}
