package fi.bullpen.kmpapp.data.turnkey

import dev.whyoleg.cryptography.algorithms.asymmetric.EC
import dev.whyoleg.cryptography.algorithms.digest.SHA256
import dev.whyoleg.cryptography.random.CryptographyRandom
import fi.bullpen.kmpapp.data.turnkey.dto.*
import fi.bullpen.kmpapp.data.utils.HttpHeader
import fi.bullpen.kmpapp.data.utils.compressECDSAPublicKey
import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper
import fi.bullpen.kmpapp.service.webauthn.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.utils.io.charsets.*
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.io.encoding.Base64

sealed interface TurnkeyAuthStamper {
    suspend fun stampBody(requestBodySerialized: String): HttpHeader
}

class TurnkeyAuthApiKeyStamper(private val turnkeyApiKeyRepository: TurnkeyApiKeyRepository) : TurnkeyAuthStamper {

    @Serializable
    private data class ApiStamp(
        val publicKey: String,
        val signature: String,
        val scheme: String,
    )

    private fun encodeDERInteger(bytes: ByteArray): ByteArray {
        // Find the first non-zero byte (to skip leading zeros)
        var firstNonZeroIndex = bytes.indexOfFirst { it != 0.toByte() }
        if (firstNonZeroIndex == -1) {  // All bytes are zero
            firstNonZeroIndex = bytes.size - 1
        }

        // Copy the relevant part of the byte array (skip leading zeros)
        val relevantBytes = bytes.copyOfRange(firstNonZeroIndex, bytes.size)

        // If the highest bit of the first relevant byte is 1, prepend a zero byte to denote positive integer in DER
        val result = if (relevantBytes[0].toInt() and 0x80 != 0) byteArrayOf(0x00) + relevantBytes else relevantBytes

        // Encode length
        val length = result.size
        val header = byteArrayOf(0x02, length.toByte())  // 0x02 for integer type

        return header + result
    }

    private fun rawToDerSignature(rawSig: ByteArray): ByteArray {
        if (rawSig.size != 64) {
            throw IllegalArgumentException("Raw signature must be exactly 64 bytes")
        }

        // Split raw signature into r and s components
        val r = rawSig.copyOfRange(0, 32)
        val s = rawSig.copyOfRange(32, 64)

        val encodedR = encodeDERInteger(r)
        val encodedS = encodeDERInteger(s)

        // Calculate the total length of the sequence
        val totalLength = encodedR.size + encodedS.size
        val sequenceHeader = byteArrayOf(0x30, totalLength.toByte())  // 0x30 for sequence

        return sequenceHeader + encodedR + encodedS
    }

    override suspend fun stampBody(requestBodySerialized: String): HttpHeader {
        val keyPair = turnkeyApiKeyRepository.get() ?: throw Error("No keypair found")
//      Der signature not supported?:
//      https://github.com/whyoleg/cryptography-kotlin/blob/138bb7076645105a48885e12c61199916ab538df/cryptography-providers/webcrypto/src/commonMain/kotlin/algorithms/WebCryptoEcdsa.kt#L34
//      val signature =
//          keyPair.privateKey.signatureGenerator(SHA256, ECDSA.SignatureFormat.DER)
//              .generateSignature(requestBodySerialized.encodeToByteArray())
        val signatureRaw =
            keyPair.privateKey.signatureGenerator(SHA256).generateSignature(requestBodySerialized.encodeToByteArray())
        val signatureDer = rawToDerSignature(signatureRaw)
        val publicKey = keyPair.publicKey.encodeTo(EC.PublicKey.Format.RAW).compressECDSAPublicKey().toHexString()
        val stamp = ApiStamp(
            publicKey = publicKey, signature = signatureDer.toHexString(), scheme = "SIGNATURE_SCHEME_TK_API_P256"
        )
        val stampSerialized = Json.encodeToString(stamp)
        val stampEncoded = Base64.UrlSafe.encode(stampSerialized.encodeToByteArray()).trimEnd('=')
        return HttpHeader("X-Stamp", stampEncoded)
    }
}

class TurnkeyAuthWebauthnStamper(private val webauthnService: WebauthnService) : TurnkeyAuthStamper {
    override suspend fun stampBody(requestBodySerialized: String): HttpHeader {
        val stamp = createTurnkeyWebauthnStamp(webauthnService, requestBodySerialized)
        return HttpHeader("X-Stamp-Webauthn", Json.encodeToString(stamp))
    }
}

class TurnkeyAuthIframeStamper(private val iframeStamper: IframeStamper) : TurnkeyAuthStamper {
    override suspend fun stampBody(requestBodySerialized: String): HttpHeader {
        return iframeStamper.stamp(requestBodySerialized)
    }
}

const val walletManagementSessionApiKeyName = "Wallet Management Session"


interface TurnkeyApi {

    suspend fun createApiKeys(
        authStamper: TurnkeyAuthStamper, organizationId: String, createApiKeysIntent: CreateApiKeysIntent
    )

    suspend fun updateUser(authStamper: TurnkeyAuthStamper, organizationId: String, updateUserIntent: UpdateUserIntent)
    suspend fun recover(
        iframeStamper: TurnkeyAuthIframeStamper, organizationId: String, recoverUserIntent: RecoverUserIntent
    )

    suspend fun deleteApiKey(
        authStamper: TurnkeyAuthStamper, organizationId: String, deleteApiKeysIntent: DeleteApiKeysIntent
    )

    suspend fun deleteAuthenticators(
        authStamper: TurnkeyAuthStamper, organizationId: String, deleteAuthenticatorsIntent: DeleteAuthenticatorsIntent
    )

    suspend fun createTurnkeyWebauthnAuthenticator(authenticatorName: String): AuthenticatorParamsV2

    suspend fun createAuthenticators(
        authStamper: TurnkeyAuthStamper,
        organizationId: String,
        createAuthenticatorsIntentV2: CreateAuthenticatorsIntentV2
    )

    suspend fun getUser(authStamper: TurnkeyAuthStamper, getUserQuery: GetUserQuery): TurnkeyUserResponse
}


class KtorTurnkeyApi(
    private val client: HttpClient, private val webauthnService: WebauthnService,
) : TurnkeyApi {

    private suspend inline fun <reified I : Intent> submitActivityRequest(
        endpoint: String,
        authStamper: TurnkeyAuthStamper,
        type: String,
        organizationId: String,
        parameters: I,
    ) {
        val requestBody = TurnkeyActivityRequestBody(
            type, timestampMs = Clock.System.now().toEpochMilliseconds().toString(), organizationId, parameters
        )
        val requestBodySerialized = Json.encodeToString(requestBody)
        val stamp = authStamper.stampBody(requestBodySerialized)
        val response: HttpResponse = client.post(endpoint) {
            header(
                stamp.key, stamp.value
            )
            // for some reason cors fails when using application/json
            setBody(requestBodySerialized)
            contentType(ContentType.Text.Plain.withCharset(Charsets.UTF_8))
        }
        when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {}

            else -> {
                val body = response.bodyAsText()
                throw Error("$status Error: ${body.ifEmpty { "No response body" }}")
            }
        }
    }

    private suspend inline fun <reified Q : TurnkeyQueryRequestBody, reified R> queryRequest(
        endpoint: String,
        authStamper: TurnkeyAuthStamper,
        query: Q,
    ): R {
        val requestBodySerialized = Json.encodeToString(query)
        val stamp = authStamper.stampBody(requestBodySerialized)
        val response: HttpResponse = client.post(endpoint) {
            header(
                stamp.key, stamp.value
            )
            // for some reason cors fails when using application/json
            setBody(requestBodySerialized)
            contentType(ContentType.Text.Plain.withCharset(Charsets.UTF_8))
        }
        return when (val status: HttpStatusCode = response.status) {
            HttpStatusCode.OK -> {
                response.body()
            }

            else -> {
                val body = response.bodyAsText()
                throw Error("$status Error: ${body.ifEmpty { "No response body" }}")
            }
        }
    }

    override suspend fun createApiKeys(
        authStamper: TurnkeyAuthStamper, organizationId: String, createApiKeysIntent: CreateApiKeysIntent
    ) {
        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/create_api_keys",
            authStamper,
            "ACTIVITY_TYPE_CREATE_API_KEYS",
            organizationId,
            createApiKeysIntent
        )
    }

    override suspend fun updateUser(
        authStamper: TurnkeyAuthStamper, organizationId: String, updateUserIntent: UpdateUserIntent
    ) {
        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/update_user",
            authStamper,
            "ACTIVITY_TYPE_UPDATE_USER",
            organizationId,
            updateUserIntent
        )
    }

    override suspend fun createTurnkeyWebauthnAuthenticator(
        authenticatorName: String
    ): AuthenticatorParamsV2 {
        val challenge = CryptographyRandom.nextBytes(32)
        val authenticatorUserId = CryptographyRandom.nextBytes(32)

        // All algorithms can be found here: https://www.iana.org/assignments/cose/cose.xhtml#algorithms
        // Only ES256 and RS256 are supported: https://docs.turnkey.com/passkeys/options
        val es256 = -7
        val rs256 = -257
        // This constant designates the type of credential we want to create.
        // The enum only supports one value, "public-key"
        // https://www.w3.org/TR/webauthn-2/#enumdef-publickeycredentialtype
        val publicKey = "public-key"

        val attestation = webauthnService.getWebAuthnAttestation(
            CredentialCreationOptions(
                publicKey = PublicKeyCredentialCreationOptions(
                    rp = RelyingParty(id = "bullpen.fi", name = "Bullpen Turnkey Wallet"),
                    challenge = challenge,
                    pubKeyCredParams = arrayOf(
                        PublicKeyCredentialParameter(
                            type = publicKey, alg = es256
                        ), PublicKeyCredentialParameter(
                            type = publicKey, alg = rs256
                        )
                    ),
                    user = User(
                        id = authenticatorUserId, name = authenticatorName, displayName = authenticatorName
                    ),
                    authenticatorSelection = AuthenticatorSelection(
                        authenticatorAttachment = "platform",
                        requireResidentKey = false,
                        residentKey = "preferred",
                        userVerification = "preferred"
                    )
                )
            )
        )
        return AuthenticatorParamsV2(
            authenticatorName = authenticatorName,
            challenge = Base64.UrlSafe.encode(challenge).trimEnd('='),
            attestation = attestation
        )
    }

    override suspend fun recover(
        iframeStamper: TurnkeyAuthIframeStamper, organizationId: String, recoverUserIntent: RecoverUserIntent
    ) {

        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/recover_user",
            iframeStamper,
            "ACTIVITY_TYPE_RECOVER_USER",
            organizationId,
            recoverUserIntent
        )
    }

    override suspend fun deleteApiKey(
        authStamper: TurnkeyAuthStamper, organizationId: String, deleteApiKeysIntent: DeleteApiKeysIntent
    ) {
        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/delete_api_keys",
            authStamper,
            "ACTIVITY_TYPE_DELETE_API_KEYS",
            organizationId,
            deleteApiKeysIntent
        )
    }

    override suspend fun deleteAuthenticators(
        authStamper: TurnkeyAuthStamper, organizationId: String, deleteAuthenticatorsIntent: DeleteAuthenticatorsIntent
    ) {
        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/delete_authenticators",
            authStamper,
            "ACTIVITY_TYPE_DELETE_AUTHENTICATORS",
            organizationId,
            deleteAuthenticatorsIntent
        )
    }

    override suspend fun createAuthenticators(
        authStamper: TurnkeyAuthStamper,
        organizationId: String,
        createAuthenticatorsIntentV2: CreateAuthenticatorsIntentV2
    ) {

        submitActivityRequest(
            "https://api.turnkey.com/public/v1/submit/delete_authenticators",
            authStamper,
            "ACTIVITY_TYPE_CREATE_AUTHENTICATORS_V2",
            organizationId,
            createAuthenticatorsIntentV2
        )
    }

    override suspend fun getUser(authStamper: TurnkeyAuthStamper, getUserQuery: GetUserQuery): TurnkeyUserResponse {
        return queryRequest(
            "https://api.turnkey.com/public/v1/query/get_user", authStamper, getUserQuery
        )
    }
}