package fi.bullpen.kmpapp.data.turnkey

import dev.whyoleg.cryptography.CryptographyProvider
import dev.whyoleg.cryptography.algorithms.asymmetric.EC
import dev.whyoleg.cryptography.algorithms.asymmetric.ECDSA

interface TurnkeyApiKeyRepository {
    suspend fun refresh()
    fun get(): ECDSA.KeyPair?
}

class InMemoryTurnkeyApiKeyRepository : TurnkeyApiKeyRepository {
    private var key: ECDSA.KeyPair? = null

    override suspend fun refresh() {
        val generator = CryptographyProvider.Default.get(ECDSA).keyPairGenerator(EC.Curve.P256)
        key = generator.generateKey()
    }

    override fun get(): ECDSA.KeyPair? {
        return key
    }
}