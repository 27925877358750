package fi.bullpen.kmpapp.screens.home

import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthToken

data class HomeScreenState(
    val telegramBotName: String,
    val authState: AuthState = AuthState.Standby,
    val emailRecoverySetupNeeded: Boolean = false,
    val telegramUserAuthToken: TelegramUserAuthToken,
) {
    val userDisplayName: String = telegramUserAuthToken.username ?: telegramUserAuthToken.firstName
    val userProfilePicture: String? = telegramUserAuthToken.photoUrl
}

sealed interface AuthState {
    sealed interface AwaitingAction : AuthState
    data object Standby : AwaitingAction

    data object InProgress : AuthState
    data class Error(val message: String) : AwaitingAction
    data object PasskeyLoginNeeded : AwaitingAction
    data object SignedIn : AuthState
}
