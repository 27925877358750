package fi.bullpen.kmpapp.data.turnkey.dto

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Authenticator(
    val transports: List<String>,
    val attestationType: String,
    val aaguid: String,
    val credentialId: String,
    val model: String,
    val credential: Credential,
    val authenticatorId: String,
    val authenticatorName: String,
    val createdAt: DateTime,
    val updatedAt: DateTime,
) {
    @OptIn(FormatStringsInDatetimeFormats::class)
    @Transient
    private val dateTimeFormat = LocalDateTime.Format {
        byUnicodePattern("yy-MM-dd")
    }

    @Transient
    val displayCreatedAt: String = dateTimeFormat.format(
        Instant.fromEpochSeconds(createdAt.seconds.toLong()).toLocalDateTime(TimeZone.currentSystemDefault())
    )
}