package fi.bullpen.kmpapp.screens.home

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import fi.bullpen.kmpapp.composables.Scaffold
import fi.bullpen.kmpapp.screens.turnkeyInitEmailRecovery.TurnkeyInitEmailRecoveryScreen
import fi.bullpen.kmpapp.screens.turnkeyManagement.TurnkeyManagementScreen
import fi.bullpen.kmpapp.screens.turnkeyRecovery.TurnkeyRecoveryScreen
import fi.bullpen.kmpapp.screens.utils.getScreenModel
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*

data object HomeScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val screenModel: HomeScreenModel = getScreenModel()
        val screenState by screenModel.state.collectAsState()
        LaunchedEffect(Unit) {
            screenModel.onInit()
        }

        if (screenState.authState is AuthState.SignedIn) {
            navigator.replace(TurnkeyManagementScreen)
        }
        if (screenState.emailRecoverySetupNeeded) {
            navigator.replace(TurnkeyInitEmailRecoveryScreen)
        }
        val uiDisabled = screenState.authState !is AuthState.AwaitingAction

        Scaffold(screenState.telegramBotName) {

            UserHeader(screenState.userDisplayName, screenState.userProfilePicture)

            Div({ classes("mt-10") }) {
                Form(attrs = {
                    classes("space-y-4", "p-4", "max-w-lg", "mx-auto")
                }) {
                    if (screenState.authState is AuthState.Error) {
                        Div({
                            classes(
                                "bg-red-100",
                                "border",
                                "border-red-400",
                                "text-red-700",
                                "px-4",
                                "py-3",
                                "rounded",
                                "relative"
                            )
                            attr("role", "alert")
                        }) {
                            Div({
                                classes("font-bold")
                            }) {
                                Text("Authentication Error:")
                            }
                            Span({
                                classes("block", "sm:inline")
                            }) {
                                Text((screenState.authState as AuthState.Error).message)
                            }
                        }
                    }
                    LoginButton(uiDisabled) {
                        screenModel.onLogin()
                    }
                }
            }
            EmailRecovery(uiDisabled) {
                navigator.replace(TurnkeyRecoveryScreen)
            }
        }
    }
}

@Composable
private fun UserHeader(userDisplayName: String, userProfilePicture: String?) {
    Div({ classes("mt-48") }) {
        // todo: handle no profile picture and placeholder while loading
        userProfilePicture?.let {
            Img(src = it, alt = "User Profile Picture", attrs = {
                classes("mx-auto", "rounded-full")
                attr("width", "80")
                attr("height", "80")
            })
        }
        H2({
            classes(
                "mt-4", "text-center", "text-3xl", "favorit", "leading-9", "tracking-tight", "text-zinc-900"
            )
        }) {
            Text("Welcome, $userDisplayName")
        }
    }
}

@Composable
private fun LoginButton(disabled: Boolean, onClick: () -> Unit) {
    Button({
        classes(
            "w-full",
            "justify-center",
            "rounded-md",
            "bg-zinc-900",
            "py-3",
            "text-sm",
            "font-semibold",
            "text-white",
            "shadow-sm",
            "hover:bg-zinc-800",
            "focus-visible:outline",
            "focus-visible:outline-2",
            "focus-visible:outline-offset-2",
            "focus-visible:outline-zinc-900",
            "disabled:hover:bg-zinc-900",
            "disabled:opacity-75"
        )
        if (disabled) {
            disabled()
        }
        type(ButtonType.Submit)
        onClick {
            it.preventDefault()
            onClick()
        }
    }) {
        Text("Login with Passkey")
    }
}

@Composable
private fun EmailRecovery(disabled: Boolean, onClick: () -> Unit) {
    Div({
        classes("px-4", "max-w-lg", "mx-auto", "flex", "justify-center", "space-x-2")
    }) {
        A(attrs = {
            style {
                cursor("pointer")
            }
            classes(
                "font-medium",
            )
            if (disabled) {
                classes(
                    "text-gray-400",
                    "dark:text-gray-500",
                )
            } else {
                classes(
                    "hover:underline",
                    "text-blue-500",
                    "dark:text-blue-500",
                )
            }
            onClick {
                it.preventDefault()
                onClick()
            }
        }) {
            Text("Register passkey for new device")
        }
    }
}
