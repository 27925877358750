package fi.bullpen.kmpapp.data.bullpen

import fi.bullpen.kmpapp.data.bullpen.responses.UsersTurnkeyInfo


data class UserInfo(
    val id: String,
    val usersTurnkeyInfo: UsersTurnkeyInfo
)

interface UserInfoRepository {
    fun save(userInfo: UserInfo)
    fun get(): UserInfo?
}

class InMemoryUserInfoRepository : UserInfoRepository {
    private var userInfo: UserInfo? = null

    override fun save(userInfo: UserInfo) {
        this.userInfo = userInfo
    }

    override fun get(): UserInfo? {
        return userInfo
    }
}