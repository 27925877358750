package fi.bullpen.kmpapp.di

import fi.bullpen.kmpapp.Config
import fi.bullpen.kmpapp.data.bullpen.BullpenApi
import fi.bullpen.kmpapp.data.bullpen.InMemoryUserInfoRepository
import fi.bullpen.kmpapp.data.bullpen.KtorBullpenApi
import fi.bullpen.kmpapp.data.bullpen.UserInfoRepository
import fi.bullpen.kmpapp.data.telegram.InMemoryTelegramUserAuthTokenRepository
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthTokenRepository
import fi.bullpen.kmpapp.data.turnkey.*
import fi.bullpen.kmpapp.screens.auth.AuthScreenModel
import fi.bullpen.kmpapp.screens.home.HomeScreenModel
import fi.bullpen.kmpapp.screens.turnkeyInitEmailRecovery.TurnkeyInitEmailRecoveryScreenModel
import fi.bullpen.kmpapp.screens.turnkeyManagement.TurnkeyManagementScreenModel
import fi.bullpen.kmpapp.screens.turnkeyRecovery.TurnkeyRecoveryScreenModel
import fi.bullpen.kmpapp.service.webauthn.WebauthnService
import fi.bullpen.kmpapp.service.webauthn.getPlatformWebauthnStamper
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.*
import kotlinx.serialization.json.Json
import org.koin.core.context.startKoin
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.module

val dataModule = module {
    single {
        val json = Json { ignoreUnknownKeys = true }
        HttpClient {
            install(ContentNegotiation) {
                json(json)
            }
            install(HttpTimeout) {
                requestTimeoutMillis = 600000
                connectTimeoutMillis = 600000
                socketTimeoutMillis = 600000
            }

            defaultRequest {
                // https://slack-chats.kotlinlang.org/t/13823683/hi-is-there-a-way-to-replace-headers-in-ktor-i-initialize-cl
//                contentType(ContentType.Application.Json)
                headers.appendIfNameAbsent(HttpHeaders.ContentType, ContentType.Application.Json.toString())
            }
        }
    }

    single<TelegramUserAuthTokenRepository> { InMemoryTelegramUserAuthTokenRepository() }
    single<TurnkeyApiKeyRepository> { InMemoryTurnkeyApiKeyRepository() }
    single<UserInfoRepository> { InMemoryUserInfoRepository() }
    single<BullpenApi> { KtorBullpenApi(get(), get(), get<Config>().apiUrl) }
    single<TurnkeyApi> { KtorTurnkeyApi(get(), get()) }
    single<TurnkeyAuthWebauthnStamper> { TurnkeyAuthWebauthnStamper(get()) }
    single<WebauthnService> { getPlatformWebauthnStamper() }
}

val screenModelsModule = module {
    factoryOf(::AuthScreenModel)
    factoryOf(::HomeScreenModel)
    factoryOf(::TurnkeyInitEmailRecoveryScreenModel)
    factoryOf(::TurnkeyRecoveryScreenModel)
    factoryOf(::TurnkeyManagementScreenModel)
}

fun initKoin(config: Config) {
    startKoin {
        modules(
            module {
                single { config }
            },
            dataModule,
            screenModelsModule,
        )
    }
}
