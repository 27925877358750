package fi.bullpen.kmpapp.screens.turnkeyManagement.composeables

import androidx.compose.runtime.Composable
import fi.bullpen.kmpapp.composables.WarningIcon
import org.jetbrains.compose.web.dom.*

@Composable
fun ConfirmDeletionModal(
    secondaryMessage: String, onCancel: () -> Unit, onConfirm: () -> Unit
) {
    Div({
        classes(
            "fixed",
            "z-20",
            "left-0",
            "top-0",
            "w-screen",
            "h-screen",
            "flex",
            "items-center",
            "justify-center",
            "bg-black",
            "bg-opacity-50"
        )
        onClick {
            onCancel()
        }
    }) {
        Div({
            classes("relative", "bg-white", "rounded-lg", "shadow", "dark:bg-gray-700")
            onClick {
                it.stopPropagation()
            }
        }) {
            Div({ classes("p-4", "md:p-5", "text-center") }) {
                WarningIcon { classes("mx-auto", "mb-4", "text-gray-400", "w-12", "h-12", "dark:text-gray-200") }
                H3({ classes("text-lg", "font-semibold", "mt-2") }) {
                    Text("Are you sure?")
                }
                P({ classes("mb-5", "text-lg", "font-normal", "text-gray-600", "mt-2") }) {
                    Text(secondaryMessage)
                }
                Button({
                    classes(
                        "text-white",
                        "bg-red-600",
                        "hover:bg-red-800",
                        "focus:ring-4",
                        "focus:outline-none",
                        "focus:ring-red-300",
                        "dark:focus:ring-red-800",
                        "font-medium",
                        "rounded-lg",
                        "text-sm",
                        "inline-flex",
                        "items-center",
                        "px-5",
                        "py-2.5",
                        "text-center"
                    )
                    onClick {
                        onConfirm()
                    }
                }) {
                    Text("Yes, I'm sure")
                }
                Button({
                    classes(
                        "py-2.5",
                        "px-5",
                        "ms-3",
                        "text-sm",
                        "font-medium",
                        "text-gray-900",
                        "focus:outline-none",
                        "bg-white",
                        "rounded-lg",
                        "border",
                        "border-gray-200",
                        "hover:bg-gray-100",
                        "hover:text-blue-700",
                        "focus:z-10",
                        "focus:ring-4",
                        "focus:ring-gray-100",
                        "dark:focus:ring-gray-700",
                        "dark:bg-gray-800",
                        "dark:text-gray-400",
                        "dark:border-gray-600",
                        "dark:hover:text-white",
                        "dark:hover:bg-gray-700"
                    )
                    onClick {
                        onCancel()
                    }
                }) { Text("No Cancel") }
            }
        }
    }
}