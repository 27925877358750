package fi.bullpen.kmpapp.composables

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.svg.*
import org.w3c.dom.svg.SVGElement

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
fun XmarkIcon(attrs: AttrBuilderContext<SVGElement>? = null) {
    Svg(viewBox = "0 0 24 24", attrs = {
        attr("aria-hidden", "true")
        xmlns("http://www.w3.org/2000/svg")
        fill("none")
        viewBox("0 0 14 14")
        attrs?.invoke(this)
    }) {
        Path(d = "m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6", attrs = {
            attr("stroke", "currentColor")
            attr("stroke-linecap", "round")
            attr("stroke-linejoin", "round")
            attr("stroke-width", "2")
        })
    }
}