package fi.bullpen.kmpapp.data.telegram

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TelegramUserAuthToken(
    @SerialName("auth_date") val authDate: ULong,
    @SerialName("first_name") val firstName: String,
    @SerialName("last_name") val lastName: String?,
    val hash: String,
    val id: ULong,
    @SerialName("photo_url") val photoUrl: String?,
    val username: String?,
)