package fi.bullpen.kmpapp.data.turnkey

import dev.whyoleg.cryptography.CryptographyProvider
import dev.whyoleg.cryptography.algorithms.digest.SHA256
import fi.bullpen.kmpapp.service.webauthn.*
import kotlinx.serialization.Serializable

@OptIn(ExperimentalStdlibApi::class)
internal suspend fun createTurnkeyWebauthnStamp(
    webauthnService: WebauthnService, requestBody: String
): WebAuthnStamp {
    val timeout = 60uL * 1000uL // one minute
    val encodedChallenge = getChallengeFromPayload(requestBody).toHexString().encodeToByteArray()
    val credential = try {
        webauthnService.webauthnCredentialGet(
            CredentialRequestOptions(
                PublicKeyCredentialRequestOptions(
                    rpId = "bullpen.fi",
                    challenge = encodedChallenge,
                    allowCredentials = emptyArray(),
                    timeout = timeout,
                    userVerification = UserVerificationRequirement.PREFERRED
                )
            )
        )
    } catch (t: Throwable) {
        throw Error("Error during webauthn prompt: $t", t)
    }

    return WebAuthnStamp(
        credentialId = credential.id,
        authenticatorData = credential.response.authenticatorData,
        clientDataJson = credential.response.clientDataJSON,
        signature = credential.response.signature
    )
}


private suspend fun getChallengeFromPayload(payload: String): ByteArray {
    return CryptographyProvider.Default.get(SHA256).hasher().hash(payload.encodeToByteArray())
}


@Serializable
internal data class WebAuthnStamp(
    @Serializable(with = Base64Serializer::class) val authenticatorData: ByteArray,
    @Serializable(with = Base64Serializer::class) val clientDataJson: ByteArray,
    val credentialId: String,
    @Serializable(with = Base64Serializer::class) val signature: ByteArray,
)
