package fi.bullpen.kmpapp.screens.turnkeyManagement.composeables

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text


@Composable
fun EnabledButton(enabled: Boolean, onClick: () -> Unit) {
    Button({
        classes(
            "mt-4",
            "py-2.5",
            "px-5",
            "text-sm",
            "font-medium",
            "text-gray-900",
            "focus:outline-none",
            "bg-white",
            "rounded-lg",
            "border",
            "border-gray-200",
            "hover:text-red-700",
            "focus:z-10",
            "focus:ring-4",
            "focus:ring-gray-100",
            "dark:focus:ring-gray-700",
            "dark:bg-gray-800",
            "dark:text-gray-400",
            "dark:border-gray-600",
            "dark:hover:text-white",
            "dark:hover:bg-gray-700"
        )
        onClick {
            onClick()
        }
        onMouseEnter { event ->
            event.preventDefault()
        }
    }) {
        Text(if (enabled) "Disable" else "Enable")
    }
}