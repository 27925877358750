package fi.bullpen.kmpapp.data.utils

fun ByteArray.compressECDSAPublicKey(): ByteArray {
    if (size != 65 || this[0] != 0x04.toByte()) {
        throw IllegalArgumentException("Invalid uncompressed public key")
    }

    // Extract the X and Y coordinates (skip the first byte)
    val x = sliceArray(1..32)
    val y = sliceArray(33..64)

    // Determine the prefix based on the parity of the Y coordinate's last byte
    val prefix = if (y.last().toInt() and 1 == 0) 0x02 else 0x03

    // Return the compressed key (prefix + X coordinate)
    return byteArrayOf(prefix.toByte()) + x
}