package fi.bullpen.kmpapp.service.webauthn

import io.ktor.util.*
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.js.JsPlainObject
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonPrimitive
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.khronos.webgl.Uint8Array
import kotlin.io.encoding.Base64
import kotlin.js.Promise

external interface NavigatorCredentials {
    val credentials: CredentialsContainer
}

external interface CredentialsContainer {
    fun create(options: JsCredentialCreationOptions): Promise<JsPublicKeyCredential<JsAuthenticatorAttestationResponse>>
    fun get(options: JsCredentialRequestOptions): Promise<JsPublicKeyCredential<JsAuthenticatorAssertionResponse>>
}

external interface JsAuthenticatorResponse {
    val clientDataJSON: ArrayBuffer
}

// Define an external interface to represent the AuthenticatorAttestationResponse
external interface JsAuthenticatorAttestationResponse : JsAuthenticatorResponse {
    val attestationObject: ArrayBuffer
    val transports: Array<String>?
}

external interface JsAuthenticatorAssertionResponse : JsAuthenticatorResponse {
    val authenticatorData: ArrayBuffer
    val signature: ArrayBuffer
    val userHandle: ArrayBuffer
}

// Define an external interface to represent the PublicKeyCredential
external interface JsPublicKeyCredential<R : JsAuthenticatorResponse> {
    val rawId: ArrayBuffer
    val response: R
    val authenticatorAttachment: String
    val id: String
    val type: String
}


@JsPlainObject
external interface JsCredentialCreationOptions {
    val publicKey: JsPublicKeyCredentialCreationOptions
}

@JsPlainObject
external interface JsRelyingParty {
    val id: String
    val name: String
}


@JsPlainObject
external interface JsPublicKeyCredentialCreationOptions {
    val rp: JsRelyingParty
    val user: JsUser
    val challenge: Uint8Array
    val pubKeyCredParams: Array<JsPublicKeyCredentialParameter>
    val timeout: Float?
    val authenticatorSelection: JsAuthenticatorSelection?
    val attestation: String?
}

@JsPlainObject
external interface JsPublicKeyCredentialParameter {
    val type: String
    val alg: Int
}

@JsPlainObject
external interface JsUser {
    val id: Uint8Array
    val name: String
    val displayName: String
}

@JsPlainObject
external interface JsAuthenticatorSelection {
    val authenticatorAttachment: String?
    val requireResidentKey: Boolean
    val residentKey: String
    val userVerification: String
}

private fun ByteArray.asUInt8Array(): Uint8Array = Uint8Array(unsafeCast<Int8Array>().buffer)
private fun ArrayBuffer.asByteArray(): ByteArray = Int8Array(this).unsafeCast<ByteArray>()

fun CredentialCreationOptions.toJsCredentialCreationOptions(): JsCredentialCreationOptions {
    return JsCredentialCreationOptions(
        publicKey = JsPublicKeyCredentialCreationOptions(
            rp = JsRelyingParty(
                id = this.publicKey.rp.id,
                name = this.publicKey.rp.name,
            ),
            challenge = this.publicKey.challenge.asUInt8Array(),
            pubKeyCredParams = this.publicKey.pubKeyCredParams.map {
                JsPublicKeyCredentialParameter(
                    type = it.type,
                    alg = it.alg,
                )
            }.toTypedArray(),
            user = JsUser(
                id = this.publicKey.user.id.asUInt8Array(),
                name = this.publicKey.user.name,
                displayName = this.publicKey.user.displayName,
            ),
            authenticatorSelection = JsAuthenticatorSelection(
                authenticatorAttachment = this.publicKey.authenticatorSelection.authenticatorAttachment,
                requireResidentKey = this.publicKey.authenticatorSelection.requireResidentKey,
                residentKey = this.publicKey.authenticatorSelection.residentKey,
                userVerification = this.publicKey.authenticatorSelection.userVerification,
            ),
            timeout = this.publicKey.timeout?.toFloat(),
        )
    )
}

@JsPlainObject
external interface JsCredentialRequestOptions {
    val publicKey: JsPublicKeyCredentialRequestOptions
}

@JsPlainObject
external interface JsPublicKeyCredentialRequestOptions {
    val rpId: String
    val challenge: Uint8Array
    val allowCredentials: Array<JsPublicKeyCredentialDescriptor>
    val timeout: Float
    val userVerification: String
}

@JsPlainObject
external interface JsPublicKeyCredentialDescriptor {
    val type: String
    val id: Uint8Array
    val transports: Array<String>
}

fun CredentialRequestOptions.toJsCredentialRequestOptions(): JsCredentialRequestOptions {
    return JsCredentialRequestOptions(
        publicKey = JsPublicKeyCredentialRequestOptions(
            rpId = this.publicKey.rpId,
            challenge = this.publicKey.challenge.asUInt8Array(),
            allowCredentials = this.publicKey.allowCredentials.map {
                JsPublicKeyCredentialDescriptor(
                    type = it.type,
                    id = it.id.asUInt8Array(),
                    transports = it.transports.map { transport -> Json.encodeToJsonElement(transport).jsonPrimitive.content }
                        .toTypedArray(),
                )
            }.toTypedArray(),
            timeout = this.publicKey.timeout.toFloat(),
            userVerification = Json.encodeToJsonElement(this.publicKey.userVerification).jsonPrimitive.content,
        )
    )
}

actual fun getPlatformWebauthnStamper(): WebauthnService = JsWebauthnService()

// https://github.com/tkhq/sdk/blob/1478e7b037e54a35ff0699b95659f8ff35e96d73/packages/http/src/webauthn.ts
class JsWebauthnService : WebauthnService {
    override suspend fun getWebAuthnAttestation(options: CredentialCreationOptions): V1Attestation {
        val webAuthnSupported = hasWebAuthnSupport()

        if (!webAuthnSupported) {
            throw Error("webauthn is not supported by this browser")
        }

        val res = webauthnCredentialCreate(options)
        return V1Attestation(
            credentialId = Base64.UrlSafe.encode(res.rawId).trimEnd('='),
            attestationObject = Base64.UrlSafe.encode(res.response.attestationObject).trimEnd('='),
            clientDataJson = Base64.UrlSafe.encode(res.response.clientDataJSON).trimEnd('='),
            transports = res.response.transports,
        )
    }

    override suspend fun webauthnCredentialGet(options: CredentialRequestOptions): PublicKeyCredential<AuthenticatorAssertionResponse> {
        val webAuthnSupported = hasWebAuthnSupport()

        if (!webAuthnSupported) {
            throw Error("webauthn is not supported by this browser")
        }

        val credentialsContainer = (window.navigator.asDynamic() as NavigatorCredentials).credentials
        val response = credentialsContainer.get(options.toJsCredentialRequestOptions()).await()
        console.log("webauthnCredentialGet response:", response)
        return response.toCommon()
    }

    suspend fun webauthnCredentialCreate(
        options: CredentialCreationOptions
    ): PublicKeyCredential<AuthenticatorAttestationResponse> {
        val credentialsContainer = (window.navigator.asDynamic() as NavigatorCredentials).credentials
        val response = credentialsContainer.create(options.toJsCredentialCreationOptions()).await()
        return response.toCommon()
    }

    // `hasWebAuthnSupport` checks for barebones webauthn support.
    // For additional details and granular settings, see:
    // https://web.dev/articles/passkey-form-autofill#feature-detection, https://developer.mozilla.org/en-US/docs/Web/API/PublicKeyCredential
    private fun hasWebAuthnSupport(): Boolean {
        return window.asDynamic().PublicKeyCredential != null
    }
}

private fun JsPublicKeyCredential<JsAuthenticatorAttestationResponse>.toCommon(): PublicKeyCredential<AuthenticatorAttestationResponse> {
    return PublicKeyCredential(
        rawId = this.rawId.asByteArray(),
        response = AuthenticatorAttestationResponse(
            attestationObject = this.response.attestationObject.asByteArray(),
            clientDataJSON = this.response.clientDataJSON.asByteArray(),
            transports = this.response.transports?.map {
                when (it) {
                    "AUTHENTICATOR_TRANSPORT_BLE" -> V1AuthenticatorTransport.BLE
                    "AUTHENTICATOR_TRANSPORT_INTERNAL" -> V1AuthenticatorTransport.INTERNAL
                    "AUTHENTICATOR_TRANSPORT_NFC" -> V1AuthenticatorTransport.NFC
                    "AUTHENTICATOR_TRANSPORT_USB" -> V1AuthenticatorTransport.USB
                    "AUTHENTICATOR_TRANSPORT_HYBRID" -> V1AuthenticatorTransport.HYBRID
                    else -> throw Error("Unknown transport: $it")
                }
            } ?: emptyList()),
        authenticatorAttachment = this.authenticatorAttachment,
        id = this.id,
        type = this.type,
    )
}


private fun JsPublicKeyCredential<JsAuthenticatorAssertionResponse>.toCommon(): PublicKeyCredential<AuthenticatorAssertionResponse> {
    return PublicKeyCredential(
        rawId = this.rawId.asByteArray(),
        response = AuthenticatorAssertionResponse(
            clientDataJSON = this.response.clientDataJSON.asByteArray(),
            authenticatorData = this.response.authenticatorData.asByteArray(),
            signature = this.response.signature.asByteArray(),
            userHandle = this.response.userHandle.asByteArray(),
        ),
        authenticatorAttachment = this.authenticatorAttachment,
        id = this.id,
        type = this.type,
    )
}
