package fi.bullpen.kmpapp.data.turnkey.dto

import fi.bullpen.kmpapp.service.webauthn.V1Attestation
import kotlinx.serialization.Serializable

@Serializable
data class AuthenticatorParamsV2(
    val authenticatorName: String,
    val challenge: String,
    val attestation: V1Attestation,
)